import React, { useRef, useState, useEffect, Fragment } from "react";
import { useParams, useHistory } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { BodyComponent } from '@darshanpatel2608/human-body-react';
import ExtendedBody from "./ExtendedBody";
// import { createTodo } from '../graphql/mutations';
import { Alert } from "react-bootstrap";
import styled from 'styled-components';
import { Link } from "react-router-dom";

import axios from 'axios';
// axios.defaults.baseURL = '3.135.186.254:80';
import './NewNote.css'
import { useAppContext } from "../libs/contextLib";



export default  function Notes() {
  const file = useRef(null);
  const { id } = useParams();
  const [expand, setExpand] = useState(false)

  const history = useHistory();
  const [note, setNote] = useState(null);
  const [display_index, setdisplay_index] = useState(0)
  const [content, setContent] = useState("");
  const { isAuthenticated, setParts, parts } = useAppContext();


  useEffect(() => {
    console.log('parts updated:', parts);
  }, [parts]);
  const formatDate = (date) => {

    return date.toLocaleString({timeZone:"America/New_York"});
  };
  //Give time it was created
  function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }
  

  //letters added/subtracted as user writes
  function handleTermChange(event){
    setContent({content: event.target.value})
  }

  // part is changed as person presses different body part buttons 
  function handlePartChange(event){
  //   partUndater(event)

  }
  function getRedFilledElements() {
    const allElements = document.querySelectorAll('*');
    const redFilledElements = [];
    console.log(allElements);
  
    allElements.forEach(element => {
      const fillColor = window.getComputedStyle(element).fill;
      console.log(fillColor);
      if (fillColor === 'rgb(255, 0, 0)') {
        console.log(element);
        redFilledElements.push(element.id);
      }
    });
  
    console.log('The items:');
   cop(redFilledElements)
  }
  // function partUndater(e){
  //   let val = e.target.innerHTML
  //   let parts  = part



  //   if( parts.length == 0 ){
  //     parts.push(val)
  //     setPart(parts)
  //     e.target.style.backgroundColor = "Blue"
  //     e.target.style.color = "white"
  //   }else{
    
    
    
  //   if(!parts.includes(val)){
  //     e.target.style.backgroundColor = "Blue"
  //     e.target.style.color = "white"
  //     parts.push(val)
  //     setPart(parts)
  //     return
  //   }
    
    
    
  //     let p_len = parts.length
  //       for(let i = 0; i < p_len; i++){
  //         if(parts[i] == val ){
  //           e.target.style.backgroundColor = "white"
  //           e.target.style.color = "black"
  //           let slot = parts[i]
  //           let partTwo = parts.filter(function(item) {
  //               return item !== slot })
  //             setPart(partTwo)
  //             console.log(part)
  //               break;
  //           }
  //         }
    
  //   }
  // }


function updateIndex(){
  let new_display = display_index +1
  setdisplay_index(new_display)
}

  //Send data to cloud
  async function  logData (){
    const user =6
    const userId = user.username
    const d = new Date();
    let contentShell = content
    let partShell = parts

    // gathering all the necessary pieces for item put in db
    let noteId = uuidv4();
    let day = d.getMonth() + '/' + d.getDate() + '/' + d.getFullYear()
    let partString = parts.join()
    let painDescription = contentShell.content 
    let timeCheck =  new Date();

    let param_one  =  {
      userId: userId,
      noteId: noteId,
      part: partString,
      description: painDescription, 
      tod: timeCheck
    }

  const headers = {     
       'Access-Control-Allow-Origin': '*', // Add any other headers as needed
  
    }
    
    //passing all the datapoints into createTodo schema
    // let text = "INSERT INTO noteinfo (id, part, description, tod) VALUES ($1, $2, $3, $4);"
    // let values = [ userId, noteId, partString, painDescription, timeCheck]



  //sending data using CreateTodo mutation with 3data in schema  
    try {
      console.log(userId)
      console.log(noteId)
      console.log(partString)
      console.log(painDescription)
      console.log(timeCheck)



      axios({
        method: 'post',
        url: `https://u8f8tp1qa9.execute-api.us-east-2.amazonaws.com/dev/mypath`,
       
        withCredentials: false,
        params: {
          userId: userId,
          noteId: noteId,
          part: partString,
          description: painDescription, 
          tod: timeCheck,
          path:'add'
        },
      }).then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
      
     
    //  await axios.post('https://r7ug1vyrid.execute-api.us-east-2.amazonaws.com/dev/mypath', {
    //     userId: userId,
    //     noteId: noteId,
    //     part: partString,
    //     description: painDescription, 
    //     tod: timeCheck
    //   }, {headers:headers}
    //   )
    //   .then(function (response) {
    //     console.log(response);
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
      
      
      // history.push('/')

    } catch (error) {
     console.log(error)
    }
  
  }



  // check for mistakes then start sending process to cloud
  function sendData(e){
    e.preventDefault();

    if (typeof content.content == 'undefined') {
      alert('please type in the bar')
    }else{
   logData()
   let newc = display_index + 1
setdisplay_index(newc)
    }


  }

  const handlePartClick = (event) => {
    console.log('Part clicked:', event);
    cop(event)
    // Additional functionality you want to execute on click
  };
  function displayHolder(){
    const display_slider = [ renderLander(),display1(),display2(),display3()]
    return display_slider[display_index]
  }
  

  function display1(){
    return (
      <div className="display1">
  
  <div class="btn-group" role="group" aria-label="...">
    <button type="button" class="btn btn-default"  data-toggle="button" aria-pressed="false" onClick={(e) => handlePartChange(e)}>Head</button>
    <button type="button" class="btn btn-default"  data-toggle="button" aria-pressed="false" onClick={(e) => handlePartChange(e)}>Shoulders</button>
    <button type="button" class="btn btn-default"  data-toggle="button" aria-pressed="false" onClick={(e) => handlePartChange(e)}>Chest</button>
    <button type="button" class="btn btn-default"  data-toggle="button" aria-pressed="false" onClick={(e) => handlePartChange(e)}>Back</button>
    <button type="button" class="btn btn-default"  data-toggle="button" aria-pressed="false" onClick={(e) => handlePartChange(e)}>Stomach</button>
    <button type="button" class="btn btn-default"  data-toggle="button" aria-pressed="false"onClick={(e) => handlePartChange(e)}>Waist</button> 
    <button type="button" class="btn btn-default"  data-toggle="button" aria-pressed="false" onClick={(e) => handlePartChange(e)}>Hands</button>
    <button type="button" class="btn btn-default"  data-toggle="button" aria-pressed="false" onClick={(e) => handlePartChange(e)}>Wrists</button>
     <button type="button" class="btn btn-default" data-toggle="button" aria-pressed="false"onClick={(e) => handlePartChange(e)}>Hips</button>
     <button type="button" class="btn btn-default" data-toggle="button" aria-pressed="false"onClick={(e) => handlePartChange(e)}>Thighs</button>
     <button type="button" class="btn btn-default" data-toggle="button" aria-pressed="false"onClick={(e) => handlePartChange(e)}>Feet</button>
  
  
  
  
  </div>
  
  
  
        <div class="input-group">
           <input type="text" class="form-control" placeholder="Username" aria-describedby="sizing-addon2"  onChange= {handleTermChange}/>
        </div>
        <div class="btn-group" role="group" aria-label="...">
          <button type="button" class="btn btn-default"   onClick= {sendData}  > Submit  </button>
        </div>
       
      </div>
    );
  }

  function display2(){
    return(
    <div className="pain_box">
      <div className="pain_levels">
   
          <button className="box_pain">0</button>
          <button className="box_pain">1</button>
          <button className="box_pain">2</button>
          <button className="box_pain">3</button>
          <button className="box_pain">4</button>
          <button className="box_pain">5</button>
          <button className="box_pain">6</button>
          <button className="box_pain">7</button>
          <button className="box_pain">8</button>
          <button className="box_pain">9</button>
      </div>
     <button onClick={updateIndex}> next step </button>
    </div>
    )
  }

  function display3(){
    return(
      <div>
        <h1>
          it works. Screen 3
        </h1>
      </div>
    )
  }
function cop(part){
 
  if (parts.includes(part)) {
    setParts(parts.filter(item => item !== part));
  } else {
 
      setParts([ ...parts,part ]);
  
        
      }
    // alert(parts)
}
  function renderLander() {
    function checkObjectProperties(obj) {
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          console.log(`Key: ${key}, Value: ${obj[key]}`);
        }
      }
    }
    const showBodyPart = (part) =>(e) =>{
        e.preventDefault()
        part(part)
        alert(e)

        const c = []
        c.push(part)
        console.log(c)
        setParts([...parts, c])

    };
 
    const Body = styled.div`
    font-family: system-ui, sans-serif;
    font-size: 1rem;
    line-height: 1.7;
    color: #161616;
    background-color: #f3f3f3;
    display: grid;
    min-block-size: 100vh;
    min-block-size: 100dvh;
    grid-template-columns:  ${props => (props.isExpand ? '3.5rem 1fr ' : '5rem 1fr')};
    grid-template-rows: auto 1fr auto;
    grid-template-areas:
      'sidebar header'
      'sidebar main'
      'sidebar footer';
    transition: grid-template-columns 0.3 ease;
      `;
  
  const Sidebar = styled.aside`
    position: relative;
    grid-area: sidebar;
    background-color: #1d1d29;
    color: #fff;
  `;
  
  const ResizeButton = styled.button`
    position: absolute;
    inset: 4.5rem -0.75rem auto auto;
    width: 1.5rem;
    aspect-ratio: 1;
    background-color: #fff;
    border: transparent;
    border-radius: 50%;
    cursor: pointer;
  `;
  
  const Header = styled.header`
    grid-area: header;
    background-color: #fff;
    padding: 1.25rem;
  `;
  
  const Main = styled.main`
    grid-area: main;
    padding: 1.25rem;

    display:grid;
    gap:1rem;

    @media (width > 768px){
      grid-template-columns: repeat(4, 1fr)
    }
  `;
  
  const Box = styled.div`
    padding: 1rem;
    background-color:#fff;
    border-radius:0.5rem;
    min-block-size:5rem;
    

    &:nth-child(6){
      background-color:red;
    }
    &:nth-child(1)
    {
      background-color: orange;
    }
    &:nth-child(2)
    {
      display:grid;
      grid-template-columns: repeat(2, 1fr);
   
      
    }
#.chest{background-color:red;}
    @media (width > 768px){
      &:nth-child(1)
      {
        grid-area:1/ span 3;     }
        &:nth-child(2)
        {
          grid-area:1/ span 1;   
          display:grid;
          grid-template-columns: repeat(2, 1fr);

            }
        &:nth-child(3),
        &:nth-child(4)
        {
          grid-area:2/ span 2;     }

  `;

  const bug = () => {alert(parts)}
  const Footer = styled.footer`
    grid-area: footer;
    padding: 1.25rem;
  `;
  
  const Logo = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
  `;

  const Menu = styled.ul`
  display: flex;
  flex-direction: column;
  visibility:  ${props => (props.isExpand ?  'hidden': 'visible' )};

  padding: 1rem;
`;

const MenuItem = styled.li`
  color: #fff;
  text-decoration: none;
  margin: 0.5rem 0;
  list-style-type:none;
  &:hover {
    color: #ccc;
  }

  a:visited {
    color: inherit; /* or specify another color */
  }
`;

  const params = {
    head: { selected: false }  }
    return (
      <Body  isExpand={expand}>
      <Header>
        <h1>CSS grid layout</h1>
      </Header>
      <Sidebar>
        <Logo >&#9812;</Logo>
        <ResizeButton type="button"  onClick={() => setExpand(!expand)}>&#9776;</ResizeButton>
        <Menu isExpand={expand}>
        <MenuItem href="#link2"><Link to="/">Home  </Link></MenuItem>
      <MenuItem href="#link1"><Link to="/new-note">Make Note  </Link></MenuItem>
      <MenuItem href="#link3">Link 3</MenuItem>
    </Menu>
      </Sidebar>
      <Main >
      <Box>   <ExtendedBody />   </Box>
      <Box><Box>   <button onClick={getRedFilledElements}> click me</button></Box>
<Box>Dull</Box>
<Box>Throbbing</Box>
<Box>Aching</Box>
<Box>Burning</Box>
<Box>Stabbing</Box></Box>
      <Box>Shipped -3</Box>
 
      {/* <Box>Pending -4</Box>
      <Box>Revenue -5</Box>
      <Box>Users -6</Box>
      <Box>Subscriptions -7</Box>
      <Box>Analytics -8</Box>
      <Box>Inbox -9</Box>
      <Box>Calendar -10</Box>
      <Box>User Activity -11</Box>
      <Box>Sales Dynamics -12</Box>
      <Box>Tasks -12</Box> */}

      </Main>
      <Footer>
        Footer
      </Footer>
    </Body>
        );
  }

 return(
  <Fragment> 
   { displayHolder()}
  </Fragment>
    
 )
}

import React, { useState, useEffect } from "react";
import { PageHeader, ListGroup, ListGroupItem } from "react-bootstrap";
import { useAppContext } from "../libs/contextLib";
// import { listTodos } from '../graphql/queries'
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";
import axios from 'axios';
import "./Home.css";
import { Navbar, Nav, NavDropdown, FormControl, Form, Button } from 'react-bootstrap';
// import * as  queries  from "../graphql/queries";
import styled from 'styled-components';

export default function Home() {
  const [notes, setNotes] = useState([]);
  const [expand, setExpand] = useState(false)
  const { isAuthenticated, setParts, parts } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);

  const formatDate = (dater) => {
    const date = new Date(dater);

    // Options for formatting the date
    const options = {
        year: 'numeric',
        month: 'long', // 'short' for abbreviated month names
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZoneName: 'short' // Optional: for including timezone
    };
    
    // Format the date
    const formattedDate = date.toLocaleDateString('en-US', options);
    return formattedDate
  };

useEffect(
 async () => {
   
  async function onLoad() {
    await loadNotes()
    if (!isAuthenticated) {
      return;
    }
 
    
  checkState()

  }

   onLoad();
},[isAuthenticated]);

async function loadNotes () {
// const user = await Auth.currentUserInfo();
const user = 5
const userId = user.username

await axios({
  method: 'post',
  url: `https://u8f8tp1qa9.execute-api.us-east-2.amazonaws.com/dev/mypath`,
 
  withCredentials: false,
  params: {
    userId: userId,
    path:'list'
  },
}).then(function (response) {
  console.log(response.data);
  let note_list = response.data

  note_list = note_list.map(c => { 
  let dater = c.tod
  const date = new Date(dater);

  // Options for formatting the date
  const isDST = date.toLocaleTimeString('en-US', { timeZone: 'America/New_York', hour12: false }).endsWith('EDT');

  // Adjust the date to EST if it's in DST
  if (isDST) {
    date.setHours(date.getHours() - 1);
  }
  
  // Options for formatting the date
  const options = {
    year: 'numeric',
    month: 'long', // 'short' for abbreviated month names
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZone: 'America/New_York',
    timeZoneName: 'short',
  };
  
  // Format the date
  const formattedDate = date.toLocaleDateString('en-US', options);
return { ...c, tod: formattedDate };
  })
  console.log('notesd', note_list)
  setNotes(note_list)
})
.catch(function (error) {
  console.log(error);
});











console.log('userid', userId)
await axios.get('https://18.117.186.91/mypath',  { params: { userId: userId, path:'list' } }
) .then(function (response) {
  console.log('new post')

  console.log(response)
  console.log('^^ http response')
  let note_list = response.data
  console.log(note_list)
  setNotes(note_list)
})
.catch(function (error) {
  console.log(error);
});
console.log('worked adding')
console.log(notes)

// let logs =  allTodos.data.listTodos.items
// console.log(logs)

// console.log('ss')
// setNotes(logs)
}
const handleClick = (e) => {
  e.preventDefault()
  setExpand(!expand)
  console.log(expand)
}


  function renderLander() {


 
    const Body = styled.div`
    font-family: system-ui, sans-serif;
    font-size: 1rem;
    line-height: 1.7;
    color: #161616;
    background-color: #f3f3f3;
    display: grid;
    min-block-size: 100vh;
    min-block-size: 100dvh;
    grid-template-columns:  ${props => (props.isExpand ?  '5rem 1fr': '3.5rem 1fr ' )};
    grid-template-rows: auto 1fr auto;
    grid-template-areas:
      'sidebar header'
      'sidebar main'
      'sidebar footer';
    transition: grid-template-columns 0.3 ease;
      `;
  
  const Sidebar = styled.aside`
    position: relative;
    grid-area: sidebar;
    background-color: #1d1d29;
    color: #fff;
  `;
  
  const ResizeButton = styled.button`
    position: absolute;
    inset: 4.5rem -0.75rem auto auto;
    width: 1.5rem;
    aspect-ratio: 1;
    background-color: #fff;
    border: transparent;
    border-radius: 50%;
    cursor: pointer;
  `;
  
  const Header = styled.header`
    grid-area: header;
    background-color: #fff;
    padding: 1.25rem;
  `;
  
  const Main = styled.main`
    grid-area: main;
    padding: 1.25rem;

    display:grid;
    gap:1rem;

    @media (width > 768px){
      grid-template-columns: repeat(4, 1fr)
    }
  `;
  
  const Box = styled.div`
    padding: 1rem;
    background-color:#fff;
    border-radius:0.5rem;
    min-block-size:7rem;
    
    &:nth-child(1)
    {
      background-color: orange;
    }
    @media (width > 768px){
      &:nth-child(1)
      {
        grid-area:1/ span 4;     }

  `;
  const Footer = styled.footer`
    grid-area: footer;
    padding: 1.25rem;
  `;
  
  const Logo = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
  `;
  
  const Menu = styled.ul`
  display: flex;
  flex-direction: column;
  visibility:  ${props => (props.isExpand ? 'visible' : 'hidden')};

  padding: 1rem;
`;

const MenuItem = styled.li`
  color: #fff;
  text-decoration: none;
  margin: 0.5rem 0;
  list-style-type:none;
  &:hover {
    color: #ccc;
  }

  a:visited {
    color: inherit; /* or specify another color */
  }
`;

    return (
      <Body  isExpand={expand}>
      <Header>
        <h1>CSS grid layout</h1>
      </Header>
      <Sidebar>
        <Logo >&#9812;</Logo>
        <ResizeButton type="button"  onClick={handleClick}>&#9776;</ResizeButton>
        <Menu isExpand={expand}>
        <MenuItem href="#link2"><Link to="/">Home</Link>2</MenuItem>
      <MenuItem href="#link1"><Link to="/new-note">Make Note</Link></MenuItem>

      <MenuItem href="#link3">Link 3</MenuItem>
    </Menu>
      </Sidebar>
      <Main >
      <Box>Order -2</Box>
      <Box>Shipped -3</Box>
      <Box>Pending -4</Box>
      <Box>Revenue -5</Box>
      <Box>Users -6</Box>
      <Box>Subscriptions -7</Box>
      <Box>Analytics -8</Box>
      <Box>Inbox -9</Box>
      <Box>Calendar -10</Box>
      <Box>User Activity -11</Box>
      <Box>Sales Dynamics -12</Box>
      <Box>Tasks -12</Box>

      </Main>
      <Footer>
        Footer
      </Footer>
    </Body>
        );
  }
function checkState(){


  let currentState = 0

  if (currentState.length < 1){
    return baseRender()
  } 
  else {
    // return <NoteList notes={notes} />
    return(<div>
      <h1> note list will live</h1>
    </div>)
  }
}

function baseRender(){
  console.log(notes)
  console.log('aa')
  return(
    <div> 
      <h1> Thank you for using Doctorly</h1>
    </div>
  )
}

function entries_load(){
console.log(notes)

  let new_l = notes.map((c) => {
    return (
    <div class="flex flex-col bg-blue-400 h-96 m-9 w-4/5 md:w-1/4">

      <div class="h-1/2">
        <div class="flex bg-green-200 h-1/3 justify-end items-center " >
 
          <div class="m-7">
         time logged: {c.tod}
          </div>
        </div>
        <div class="flex bg-green-300 h-2/3 justify-center items-center text-center">
           <p>{c.part}</p>
        </div>
      </div>

      <div class="h-1/2">
        <div class="flex bg-green-400 h-2/3 justify-center items-center text-center" >
          <div class=" w-3/5" >
         <p class="">{c.description}</p> 
          </div>
        </div>
        <div class="flex bg-purple-400 h-1/3 justify-end items-center">
          <div class="m-8">
          <Link to={`/notes/${c.noteid}`} class=""><em>details</em></Link>
        </div>
        </div>
      </div>

 
    </div>
  )})

  
  return (
  <div class="flex container flex-col min-w-full justify-between  items-center">{new_l}</div>
    )
}




  return (
<div className="Home">
      { isAuthenticated ? checkState(): renderLander()}
    </div>
  );
}



